export const withCache = async (request, secondsToCache) => {
    let result = {};
    const requestCacheKey = `request-${request.url}`;
    const cacheTimeKey = `cacheTime-${request.url}`;
    const cacheTime = localStorage.getItem(cacheTimeKey);
    const data = localStorage.getItem(requestCacheKey) || '{}';
    result = JSON.parse(data);
    if (!cacheTime) {
        result = await request;
        localStorage.setItem(cacheTimeKey, Date.now());
    }
    if (Date.now() - cacheTime >= secondsToCache * 1000) {
        result = await request;
        localStorage.setItem(cacheTimeKey, Date.now());
    }
    if (result === {}) {
        result = await request;
    }
    if (secondsToCache === 0) {
        result = await request;
    }
    localStorage.setItem(requestCacheKey, JSON.stringify(result));
    return result;
};
