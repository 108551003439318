import React from 'react';
import moment from 'moment';

function Main({weather, location, refreshLocation, locationSearch}) {

    const inputEl = React.useRef();

    function fahrenheitToCelcius(fahrenheit) {
        // Formula: (32°F − 32) × 5/9 = 0°C
        const celcius = (fahrenheit - 32) * 5/9;
        return celcius.toFixed(0);
    }

    function precipChance(decimal) {
        return `${Math.floor(decimal*100)}%`
    }

    function formatWind(wind) {
        return Math.floor(wind);
    }

    function calculateFeelsLike(data) {
        return `${Math.ceil(data.apparentTemperature)}°F`;
    }

    return (
      <div className="main p2 pt1">
          <form className="flex">
            {/* <div onClick={refreshLocation}>Get Current Location</div> */}
            <input ref={inputEl} className="block w100 mb1 p1 search-form-input" type="text" defaultValue={`${location.city}, ${location.region}`} />
            <button className="p1 search-form-input" onClick={(e) => {
                e.preventDefault();
                locationSearch(inputEl.current.value);
            }}>Search</button>
          </form>
          <h2 className="center">{location.city || location.region} Weather</h2>
          <div className="hud center mb1 p1" style={{maxWidth: 200, margin: "0 auto"}}>
              <div className="flex justify-between">
                <h1 className="m0 display pb1">
                    {weather.currently.temperature.toFixed(0)}&deg;F
                    <div><small style={{fontSize: 12}}>Feels like {calculateFeelsLike(weather.currently)}</small></div>
                </h1>
                <h1 className="m0 display">
                    {fahrenheitToCelcius(weather.currently.temperature)}&deg;C
                </h1>
              </div><br />
                <div>{weather.currently.summary}   </div>
          </div>
          <div className="">
                <h3>Hourly</h3>
                <h2>{weather.hourly.summary}</h2>
                {weather.hourly.data.slice(0, 12).map(hour => {
                    return (
                        <div className="flex p1 justify-between items-center">
                            <div className="p1">
                                <img className="weather-icon" src={`/weather-icons/${hour.icon}.png`} alt={hour.icon} />
                            </div>
                            <div className="p1 center" style={{width: 75}}>{moment(hour.time*1000).format(`h a`)}</div>
                            <div className="p1 bold center">
                                {hour.temperature.toFixed(0)}&deg;
                                <div><small style={{fontSize: 10}}>{calculateFeelsLike(hour)}</small></div>
                            </div>
                            <div className="p1 center">
                                {precipChance(hour.precipProbability)}<br />
                                <small>Precip.</small>
                            </div>
                            <div className="p1 center">
                                {hour.uvIndex}<br />
                                <small>UV</small>
                            </div>
                            <div className="p1 center">
                                {formatWind(hour.windSpeed)}<br />
                                <small>Wind</small>
                            </div>
                        </div>
                    );
                })}
          </div>
          <div className="">
                <h3>Daily Forecast</h3>
                <h2>{weather.daily.summary}</h2>
                {weather.daily.data.map(day => {
                    return (
                        <div className="flex p1 justify-between items-center">
                            <div className="p1">
                                <img className="weather-icon" src={`/weather-icons/${day.icon}.png`} alt={day.icon} />
                            </div>
                            <div className="p1" style={{width: 100}}>
                                {moment(day.time*1000).format('dddd')}<br />
                                <small>{moment(day.time*1000).format('MMMM Do')}</small>
                            </div>
                            <div className="p1 bold center">
                                {day.temperatureHigh.toFixed(0)}&deg;
                                /&nbsp;
                                {day.temperatureMin.toFixed(0)}&deg;
                            </div>
                            <div className="p1 center">
                                {precipChance(day.precipProbability)}<br />
                                <small>Precip.</small>
                            </div>
                            <div className="p1 center">
                                {formatWind(day.windSpeed)}<br />
                                <small>Wind</small>
                            </div>
                        </div>
                    );
                })}
          </div>
          <pre>

            {/* {JSON.stringify(weather, null, 2)} */}
          </pre>
      </div>
    );
  }
  
  export default Main;