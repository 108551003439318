import React, {useState, useEffect} from 'react';
import './App.css';
import Main from './Main';
import {getWeather} from './api/darksky';
import {geocode} from './api/google';
import {Radar} from './components/Radar';

function App() {
  
  // const [locationQuery, setLocationQuery] = useState(null);
  const [weather, setWeather] = useState(null);
  const [location, setLocation] = useState(null);

  const askForLocation = () => {
    return new Promise((resolve) => {
      navigator.geolocation.getCurrentPosition(function (location) {
        resolve(location);
      });
    })
  }

  const checkLocalStorageToClear = () => {
      const localStorageKeyCount = localStorage.length;
      if (localStorageKeyCount > 4) {
            localStorage.clear();
      }
      return Promise.resolve();
  }

  const init = async (cache = true) => {

    await checkLocalStorageToClear()

    askForLocation().then( async response => {
      const coordinates = {latitude: response.coords.latitude, longitude: response.coords.longitude};
      const location = await geocode(`${coordinates.latitude},${coordinates.longitude}`, cache)
      setLocation(location);
      const weatherData = await getWeather(coordinates, false);
      setWeather(weatherData)
      console.log('location', location);
      console.log('weatherData', weatherData);
    });



  }

  const refreshLocation = (e) => {
    e.preventDefault();
    init(false);
  }

  const watchVisibility = () => {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        init();
      }
    });
  }

  const locationSearch = async (searchTerm) => {
    console.log('searchTerm', searchTerm)
    const location = await geocode(searchTerm, false);
    setLocation(location);
    const weatherData = await getWeather(location, false);
    setWeather(weatherData)
    console.log(location)

  }
  
  useEffect(() => {
    init();
    watchVisibility();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  return (
    <div className="App">
        <Radar />
      {weather &&
        <div>
          <Main weather={weather} location={location} refreshLocation={refreshLocation} locationSearch={locationSearch} />
          <div className="center p1">
            <small>
              <a target="_blank" rel="noopener noreferrer" href="https://darksky.net/poweredby/" className="link">Powered by Dark Sky</a>
            </small>
          </div>
        </div>
      }
      {!weather &&
        <div className="p2">Loading weather from Dark Sky API...</div>
      }
    </div>
  );
}

export default App;
