import React from 'react';

// const color = '#044582';
const color = 'rgb(50, 50, 50)'
const url = 'https://radar.weather.gov/?settings=v1_eyJhZ2VuZGEiOnsiaWQiOm51bGwsImNlbnRlciI6Wy05NS42NDksMzUuODU4XSwiem9vbSI6OH0sImJhc2UiOiJzdGFuZGFyZCIsImNvdW50eSI6ZmFsc2UsImN3YSI6ZmFsc2UsInN0YXRlIjpmYWxzZSwibWVudSI6dHJ1ZSwic2hvcnRGdXNlZE9ubHkiOmZhbHNlfQ%3D%3D#/';
const style = {
    position: 'fixed',
    bottom: 5,
    right: 5,
    padding: '13px 24px',
    backgroundColor: color,
    color: '#eee',
    textDecoration: 'none',
    borderRadius: 5,
    lineHeight: 1,
    fontWeight: 700,
    boxShadow: `0px 0px 39px 50px ${color}`
}
export const Radar = () => <a style={style} rel="noopener noreferrer nofollow" target="_blank" href={url}>Radar</a>