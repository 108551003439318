import {withCache} from './withCache';
import agent from 'superagent';
import _ from 'lodash';

export const geocode = async (query, cache = true) => {
    const twoWeeksInSeconds = 60*60*24*14;
    const url = `https://yu0px01yye.execute-api.us-east-1.amazonaws.com/${query}`;
    const result = await withCache(agent.get(url), (cache) ? twoWeeksInSeconds : 0);
    return extractCityState(result.body.results);
}

export const extractCityState = (locationResults) => {
    
    console.log('locationResults', locationResults)

    if (locationResults.length === 0) {
        return false;
    }

    const geometry = {
        latitude: _.get(locationResults[0], 'geometry.location.lat'),
        longitude: _.get(locationResults[0], 'geometry.location.lng'),
    }

    const region_name_component = _.find(locationResults[0].address_components, {'types': ['administrative_area_level_1']});
    const locality_name_component = _.find(locationResults[0].address_components, {'types': ['locality']});
    
    if (!region_name_component && !locality_name_component) {
        return {
            city: "",
            region: _.find(locationResults[0].address_components, {'types': ['country']}).long_name,
            ...geometry
        }
    }

    return {
        city: locality_name_component.short_name,
        region: region_name_component.short_name,
        ...geometry
    };
}